import { deleteCookie, setCookie } from '@patrianna/shared-utils/cookie'

import { SC_ENABLED } from '@patrianna/shared-utils/constants/cookies'
import type { TypedThunk } from 'store/types'

import { actions } from './slice'

export const {
  togglePWAMessage,
  saveLocationData,
  savePasswordPolicy,
  setForcementMode,
  setGoldCurrency,
  setSweepstakeEnabled,
  initApp,
  saveKYCRequiredField,
  setSoftKycAutoCompleteAddressEnabled,
  saveRedeemPolicy,
} = actions

export const saveSoftKYCOptions =
  ({
    softKycAutoCompleteAddress,
    softKycRequiredEnabled,
  }: {
    softKycRequiredEnabled: boolean
    softKycAutoCompleteAddress: boolean
  }): TypedThunk =>
  (dispatch) => {
    if (softKycAutoCompleteAddress) {
      dispatch(setSoftKycAutoCompleteAddressEnabled({ softKycAutoCompleteAddress }))
    }
    if (softKycRequiredEnabled) {
      dispatch(saveKYCRequiredField({ softKycRequiredEnabled }))
    }
  }

export const enabledSweepstake =
  (enabled: boolean): TypedThunk =>
  (dispatch) => {
    if (enabled) {
      setCookie(SC_ENABLED, '1', { path: '/', expires: new Date(Date.now() + 31536000000) })
    } else {
      deleteCookie(SC_ENABLED)
    }
    dispatch(setSweepstakeEnabled({ enabled }))
  }
