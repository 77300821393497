import type { GetAccountBonusesRequest } from '@patrianna/shared-patrianna-types/websocket/requests'
import type { GetAccountBonusesResponse } from '@patrianna/shared-patrianna-types/store/AccountBonusesModule'
import type { TypedThunk } from 'src/store/types'
import { receivedBonuses, setBonusesLoading } from 'store/modules/bonusesFlow/slice'
import { waitingForSessionDialogsData } from 'store/modules/sessionDialogs/actions'

const getAccountBonusesRequest =
  (callback?: () => void): TypedThunk =>
  (dispatch, getState, { api, errorHandler }) => {
    const data: GetAccountBonusesRequest = {
      type: 'GetAccountBonusesRequest',
    }

    dispatch(setBonusesLoading({ loading: true }))

    api
      .getAccountBonuses({})
      .then((body) => {
        dispatch(receivedBonuses({ body: body as GetAccountBonusesResponse }))
      })
      .catch((err) => {
        dispatch(errorHandler(err, data))
      })
      .finally(() => {
        dispatch(waitingForSessionDialogsData({ dailyBonuses: true }))
        dispatch(setBonusesLoading({ loading: false }))
        callback?.()
      })
  }
export default getAccountBonusesRequest
