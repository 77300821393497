import { deleteCookie } from '@patrianna/shared-utils/cookie'
import { USER_COUNTRY, USER_STATE, LOCATION_USER_COUNTRY } from '@patrianna/shared-utils/constants/userCountry'
import { LOGGED_IN, SC_ENABLED } from '@patrianna/shared-utils/constants/cookies'

export function clearAuthCookies() {
  deleteCookie(LOGGED_IN)
  deleteCookie(USER_COUNTRY)
  deleteCookie(USER_STATE)
  deleteCookie(LOCATION_USER_COUNTRY)
  deleteCookie(SC_ENABLED)
}
