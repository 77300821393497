import type { ILevel, Password, ResetPasswordManually, User } from '@patrianna/shared-patrianna-types/store/UserModule'
import type {
  AcceptRulesRequest,
  ChangePasswordRequest,
  GetAccountInfoRequest,
  GetLiveChatSettingsRequest,
  GetPaymentMetaInfoRequest,
  ResetPasswordManuallyRequest,
  SendWelcomeEmailRequest,
  SetAccountAttributionInfoRequest,
  SetAccountInfoRequest,
} from '@patrianna/shared-patrianna-types/websocket/requests'
import type {
  AcceptRulesResponse,
  ChangePasswordResponse,
  GetAccountInfoResponse,
  GetLiveChatSettingsResponse,
  GetPaymentMetaInfoResponse,
  SendWelcomeEmailResponse,
  SetAccountAttributionInfoResponse,
  SetAccountInfoResponse,
  SetAccountModeResponse,
} from '@patrianna/shared-patrianna-types/websocket/response'
import { setShouldDelayLogout } from '@patrianna/shared-store/auth'
import { nextTick } from '@patrianna/shared-utils'
import { setCookie } from '@patrianna/shared-utils/cookie'
import { isMobileScreenSize } from '@patrianna/shared-utils/helpers'
import { INVITE_FRIENDS_HIDDEN, getItemFormLocalStorageSelector, setDataToLocalStorage } from 'src/utils/localStorage'

import { IS_WELCOME_EMAIL_SUBMITED } from '@patrianna/shared-utils/constants/cookies'
import getCountryConfigClient from 'app/utils/country/getCountryConfigClient'
import { trackEvent } from 'config/analytic'
import getAccountBonusesRequest from 'services/gateway/requests/getAccountBonuses'
import { getSeonSessionId } from 'services/seon'
import { enabledSweepstake } from 'store/modules/appConfig/actions'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { initCurrencies, setActiveCurrency } from 'store/modules/currencies/actions'
import { closeLatestDialog, openDialog } from 'store/modules/dialog/actions'
import { addUnlockedGames, levelUpHandler } from 'store/modules/games/actions'
import { setOpenPrerequisites } from 'store/modules/prerequisites/actions'
import { fetchAllPromotions } from 'store/modules/promotions'
import { waitingForSessionDialogsData } from 'store/modules/sessionDialogs/actions'
import { getOffers } from 'store/modules/shop/actions'
import { getActiveGameCodeSelector } from 'store/modules/slotGameFlow/selectors'
import { openSnackbar } from 'store/modules/snackbar/actions'
import type { TypedThunk } from 'store/types'
// import { getDialogStackSelector } from 'store/modules/dialog/selectors'
// import { accumDelayedDialogs } from 'src/store/middlewares/utils'
// will add seon
import { yieldToMain } from '@patrianna/shared-utils'

import { getActiveCurrencyIdSelector } from '../currencies/selectors'

import {
  getUserIdSelector,
  getUserLegalRulesModalSettingSelector,
  getUserLegalRulesTypeSelector,
  getUserSelector,
  isLoggedInSelector,
  shouldShowInviteFriendsModalForPaidUsers,
  shouldShowInviteFriendsModalForUnpaidUsers,
} from './selectors'
import { actions } from './slice'
import { getDialogVisibilityByNameSelector } from 'store/modules/dialog/selectors'

export const {
  setFirstDepositDate,
  setIsEmailVerified,
  setKycStatus,
  setSentEmail,
  setUser,
  setUserCountry,
  setUserFlowIsRunning,
  setXPLevel,
  deleteUser,
  setIsUpdateLegals,
  setPaymentMetaDataInfo,
  setLiveChatSettings,
  setIsLoadingInfo,
} = actions

export const setLevelProgress =
  (levelInfo: ILevel): TypedThunk =>
  (dispatch, getState) => {
    const { category } = levelInfo
    const gameCode = getActiveGameCodeSelector(getState())
    const isLoyaltyProgramEnabled = process.env.LOYALTY_PROGRAM_ENABLED
    const activeCurrency = getActiveCurrencyIdSelector(getState())
    const { levelUpHandlerFeature } = getCountryConfigClient()

    switch (category) {
      case 'free_level': {
        dispatch(setXPLevel({ xpLevel: levelInfo }))
        if (levelInfo.levelChanged) {
          if (activeCurrency !== 'SC' && isLoyaltyProgramEnabled && levelUpHandlerFeature.enabled) {
            dispatch(levelUpHandler(levelInfo))
          }

          trackEvent('level_up', {
            category: gameCode,
            label: levelInfo.level.toString(),
          })
          dispatch(addUnlockedGames(levelInfo?.unlocked))
        }
        break
      }
      default: {
        break
      }
    }
  }

export const getUserPaymentMetaData =
  (callback?: () => void): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const data: GetPaymentMetaInfoRequest = { type: 'payment.GetPaymentMetaInfoRequest' }

    gateway
      .emit<GetPaymentMetaInfoResponse>(data)
      .then(({ firstDeposit, lastDeposit }) => {
        dispatch(setPaymentMetaDataInfo({ firstDeposit, lastDeposit }))
        callback?.()
      })
      .catch((err) => dispatch(errorHandler(err, data)))
  }

export const getAccountInfo =
  (isModeChangeNotification?: boolean): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const data: GetAccountInfoRequest = {
      type: 'GetAccountInfoRequest',
    }

    gateway
      .emit<GetAccountInfoResponse>(data)
      .then(({ sweepstake, balances, prerequisites, ...restUserData }) => {
        dispatch(initCurrencies({ currencies: balances }))
        dispatch(setOpenPrerequisites({ prerequisites }))
        dispatch(setUser({ data: restUserData }))
        dispatch(getUserPaymentMetaData())
        dispatch(enabledSweepstake(sweepstake))

        if (isModeChangeNotification) {
          dispatch(accountModeChangeNotificationHandler(restUserData, sweepstake))
        }
      })
      .catch((err) => {
        dispatch(errorHandler(err, data))
      })
      .finally(() => dispatch(waitingForSessionDialogsData({ legalUpdate: true })))
  }

export const updatePassword =
  (formData: Password, setSubmitting?: (arg: boolean) => void): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const data: ChangePasswordRequest = {
      type: 'ChangePasswordRequest',
      oldPassword: formData.oldPassword,
      newPassword: formData.password,
    }

    // delay logout after changing password so that "Password changed" snackbar can be displayed a bit longer
    dispatch(setShouldDelayLogout({ delayed: true }))

    gateway
      .emit<ChangePasswordResponse>(data)
      .then(() => {
        dispatch(deleteUser())
        dispatch(
          openSnackbar({
            message: 'Your password has been changed successfully. Please log in again.',
            variant: 'success',
            componentProps: {
              title: 'common.snackbar_header_text',
              buttonText: 'common.snackbar_button_text',
              isTermsShow: false,
            },
          })
        )
      })
      .catch((err) => {
        dispatch(setShouldDelayLogout({ delayed: false }))
        dispatch(errorHandler(err, data))
      })
      .finally(() => {
        setSubmitting?.(false)
      })
  }

export const resetPassword =
  (
    formData: ResetPasswordManually,
    queryParams: { rk: string; token: string },
    setSubmitting: (arg: boolean) => void,
    goToLogin: () => void
  ): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const data: ResetPasswordManuallyRequest = {
      type: 'ResetPasswordManuallyRequest',
      rk: queryParams.rk,
      token: queryParams.token,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
    }

    gateway
      .emit<ChangePasswordResponse>(data)
      .then(() => {
        dispatch(deleteUser())
        goToLogin()
        dispatch(
          openSnackbar({
            message: 'Password changed successfully. Please login again',
            variant: 'success',
          })
        )
        setSubmitting(false)
      })
      .catch((err) => {
        dispatch(errorHandler(err, data))
        setSubmitting(false)
      })
  }

// updateAccount = (info) => {
//   const { emitWS } = this.props;

//   emitWS('SetAccountInfoRequest', info);
// };

// uploadAccountWithImage = () => {
//   const {
//     _formData,
//     updateAccount,
//   } = this.props;
//   const contentType = this.getConentType(_formData.profilePhotо.name);

//   fetch(api.UPLOAD_IMAGE, {
//     method: 'POST',
//     credentials: 'include',
//     headers: {
//       'Content-Type': `image/${contentType}`,
//       'Access-Control-Expose-Headers': 'Location',
//     },
//     body: _formData.profilePhotо,
//   })
//     .then(data => {
//       const profilePhotо = data.headers.get('location');
//       updateAccount(normalizers.beforeSubmit({
//         ..._formData,
//         profilePhotо: `${api.config.GATEWAY}${profilePhotо}`,
//       }));
//     })
//     .catch(console.log);
// }

export const getDataAfterChangeAppState = (): TypedThunk => (dispatch, getState) => {
  const isLoggedIn = isLoggedInSelector(getState())
  if (isLoggedIn) {
    dispatch(getAccountInfo())
  }
}

// TODO: deactive these actions for now
// export const openWelcomeEmailDialog = (): TypedThunk => (dispatch, getState) => {
//   const isEmailSent = getSentWelcomeEmailFieldSelector(getState())
//   const isSCEnabled = sweepstakeEnabledSelector(getState())
//   const dialogs = getDialogStackSelector(getState())
//   if (!isEmailSent && isSCEnabled) {
//     if (dialogs?.length) {
//       dispatch(accumDelayedDialogs('WELCOME_EMAIL_SEND_DIALOG'))
//     } else {
//       dispatch(replaceDialog({ modalName: 'WELCOME_EMAIL_SEND_DIALOG' }))
//     }
//   }
// }

// export const openWelcomeEmailDialogWithDelay =
//   (delay: number): TypedThunk =>
//   (dispatch) => {
//     const timerId = setTimeout(() => {
//       dispatch(openWelcomeEmailDialog())
//     }, delay)
//     dispatch(setDataToLocalStorage('WELCOME_EMAIL_DIALOG_TIMER_ID', timerId))
//   }

export const sendEmailRequest =
  (): TypedThunk =>
  (dispatch, _, { gateway, errorHandler }) => {
    const data: SendWelcomeEmailRequest = {
      type: 'SendWelcomeEmailRequest',
    }

    gateway
      .emit<SendWelcomeEmailResponse>(data)
      .then(() => {
        dispatch(setSentEmail())
      })
      .catch((err) => {
        dispatch(errorHandler(err, data))
      })

    setCookie(IS_WELCOME_EMAIL_SUBMITED, '1')
    trackEvent('welcome_email_submit')
  }

export const accountModeChangedHandler =
  (body: SetAccountModeResponse | SetAccountAttributionInfoResponse): TypedThunk =>
  (dispatch) => {
    const isScEnabled = body?.sweepstake
    dispatch(enabledSweepstake(isScEnabled))
    dispatch(setUser({ data: body.account }))
    dispatch(getOffers(true))
    dispatch(fetchAllPromotions(isScEnabled))

    if (isScEnabled) {
      dispatch(setActiveCurrency({ activeCurrencyId: 'SC' }))
      dispatch(getAccountBonusesRequest())
    }

    trackEvent(
      'mode_change',
      {
        timestamp: Date.now(),
        mode_type: isScEnabled ? 'sweepstake' : 'gold',
      },
      { all: false, bloomreach: true }
    )
  }

export const accountModeChangeNotificationHandler =
  (account: User, sweepstake: boolean): TypedThunk =>
  (dispatch, getState) => {
    dispatch(getOffers(true))
    dispatch(fetchAllPromotions(sweepstake))
    dispatch(setActiveCurrency({ activeCurrencyId: sweepstake ? 'SC' : 'GC' }))
    dispatch(getAccountBonusesRequest())

    if (sweepstake) {
      const acceptedSrVersion = Number(account?.acceptedSrVersion)
      const isAcceptedSrVersion = isNaN(acceptedSrVersion) ? 0 : acceptedSrVersion
      const isQuickStartOpen = getDialogVisibilityByNameSelector(getState(), 'CURRENCY_SWITCH_QUICKSTART')

      const isSCRulesModalAvailable = !isQuickStartOpen && !isAcceptedSrVersion

      if (isSCRulesModalAvailable) {
        dispatch(openDialog({ modalName: 'CURRENCY_SWITCH_QUICKSTART' }))
      }
    }

    trackEvent(
      'mode_change',
      {
        timestamp: Date.now(),
        mode_type: sweepstake ? 'sweepstake' : 'gold',
      },
      { all: false, bloomreach: true }
    )
  }

export const acceptLegals =
  (callBack?: () => void): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const types = getUserLegalRulesTypeSelector(getState())

    const data: AcceptRulesRequest = {
      type: 'AcceptRulesRequest',
      types,
    }

    gateway
      .emit<AcceptRulesResponse>(data)
      .then((body) => {
        // Here second check is based on the 'legalRules' object to determine
        // if the user has any pending rules to apply. Related to B2SPIN-2447.
        if (body.applied || !body.account?.legalRules) {
          dispatch(setUser({ data: body.account }))
          dispatch(getAccountBonusesRequest())
          dispatch(setIsUpdateLegals({ isUpdateingLegals: false }))
          dispatch(closeLatestDialog())
          if (body.applied) {
            callBack?.()
          }
        }
      })
      .catch((err) => {
        dispatch(errorHandler(err, data))
      })
  }

export const updateUserRedeem =
  (formData: Partial<User>, openRedeem: boolean, callback?: (user: User) => void): TypedThunk =>
  async (dispatch, getState, { gateway, errorHandler }) => {
    const sweepstakeEnabled = sweepstakeEnabledSelector(getState())
    const isMobile = isMobileScreenSize()

    try {
      const session = await nextTick(getSeonSessionId)
      const data: SetAccountInfoRequest = {
        type: 'SetAccountInfoRequest',
        session,
        ...formData,
      }

      gateway
        .emit<SetAccountInfoResponse>(data)
        .then((body) => {
          dispatch(setUser({ data: body }))
          if (openRedeem) {
            if (!isMobile) {
              dispatch(closeLatestDialog())
            }
            dispatch(
              openDialog({
                modalName: 'WITHDRAW_DIALOG',
                dialogProps: { mode: sweepstakeEnabled ? 'SC' : 'USD' },
              })
            )
          }
          callback?.(body)
        })
        .catch((error) => {
          dispatch(errorHandler(error, data))

          if (error?.status?.errorText) {
            dispatch(
              openSnackbar({
                message: error.status.errorText,
              })
            )
          }
        })
        .finally(() => {
          dispatch(setUserFlowIsRunning({ userFlowIsRunning: false }))
        })
    } catch (err) {
      if (err.message) {
        dispatch(openSnackbar({ message: err?.message }))
      }
    }
  }

export const updateUser =
  (formData: Partial<User>, callback?: () => void, setSubmitting?: (arg: boolean) => void): TypedThunk =>
  async (dispatch, _, { gateway, errorHandler }) => {
    try {
      await yieldToMain()
      const session = await getSeonSessionId()
      await yieldToMain()
      const data: SetAccountInfoRequest = {
        type: 'SetAccountInfoRequest',
        session,
        ...formData,
      }

      gateway
        .emit<SetAccountInfoResponse>(data)
        .then((body) => {
          dispatch(setUser({ data: body }))
          callback?.()
        })
        .catch((error) => {
          dispatch(errorHandler(error, data))

          if (error?.status?.errorText) {
            dispatch(
              openSnackbar({
                message: error.status.errorText,
              })
            )
          }
        })
        .finally(() => {
          dispatch(setUserFlowIsRunning({ userFlowIsRunning: false }))
          setSubmitting?.(false)
        })
    } catch (err) {
      if (err.message) {
        dispatch(openSnackbar({ message: err?.message }))
      }
    }
  }

export const deeplinkModeHandler =
  (advertisingId: string, osVersion: string, appVersion: string): TypedThunk =>
  (dispatch, getState, { errorHandler, gateway }) => {
    const loggedIn = isLoggedInSelector(getState())

    if (loggedIn) {
      const data: SetAccountAttributionInfoRequest = {
        type: 'SetAccountAttributionInfoRequest',
        advertisingId,
        osVersion,
        appVersion,
      }

      gateway
        .emit<SetAccountAttributionInfoResponse>(data)
        .then((body) => {
          dispatch(accountModeChangedHandler(body))
        })
        .catch((err) => {
          dispatch(errorHandler(err, data))
        })
    }
  }

export const checkLegalsModalVisibility = (): TypedThunk => (dispatch, getState) => {
  const legalSetting = getUserLegalRulesModalSettingSelector(getState())

  const acceptLegalsUpdateSilently = () => {
    dispatch(acceptLegals())
    dispatch(setIsUpdateLegals({ isUpdateingLegals: true }))
  }

  if (legalSetting === 'Nothing') {
    acceptLegalsUpdateSilently()
  } else {
    dispatch(openDialog({ modalName: 'LEGALS_MODAL' }))
  }
}

export const checkInviteFriendsModalVisibility = (): TypedThunk => (dispatch, getState) => {
  const currentDate = new Date()
  const state = getState()
  const inviteFriendsLastDateOpened = getItemFormLocalStorageSelector(state, INVITE_FRIENDS_HIDDEN, false)

  if (shouldShowInviteFriendsModalForPaidUsers(state, inviteFriendsLastDateOpened)) {
    dispatch(openDialog({ modalName: 'INVITE_FRIENDS_DIALOG' }))
    dispatch(setDataToLocalStorage(INVITE_FRIENDS_HIDDEN, currentDate, false))
  }

  if (shouldShowInviteFriendsModalForUnpaidUsers(getState()) && !sessionStorage.getItem(INVITE_FRIENDS_HIDDEN)) {
    dispatch(openDialog({ modalName: 'INVITE_FRIENDS_DIALOG' }))
    sessionStorage.setItem(INVITE_FRIENDS_HIDDEN, currentDate.toISOString())
  }
}

export const trackKYCConfirmedEvent =
  (eventName: 'ID_Verification_Success' | 'POA_Verification_Success'): TypedThunk =>
  (dispatch, getState) => {
    const userId = getUserIdSelector(getState())
    trackEvent(eventName, { category: userId })
  }

export const getAccountInfoAndUpdateVerificationData =
  (kycStatus: User['kycStatus']): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const user = getUserSelector(getState())
    const data: GetAccountInfoRequest = { type: 'GetAccountInfoRequest' }

    gateway
      .emit<GetAccountInfoResponse>(data)
      .then(({ softKycInfo, kycInfo, status }) =>
        dispatch(setUser({ data: { ...user, softKycInfo, kycInfo, kycStatus, status } }))
      )
      .catch((err) => dispatch(errorHandler(err, data)))
  }

export const getLiveChatSettingsRequest =
  (): TypedThunk =>
  (dispatch, _, { gateway, errorHandler }) => {
    const data: GetLiveChatSettingsRequest = {
      type: 'GetLiveChatSettingsRequest',
    }

    gateway
      .emit<GetLiveChatSettingsResponse>(data)
      .then(({ showLiveChat, departments, tag, tags, purchaseFlowEnabled }) => {
        dispatch(
          setLiveChatSettings({ liveChatSettings: { showLiveChat, departments, tag, tags, purchaseFlowEnabled } })
        )
      })
      .catch((err) => dispatch(errorHandler(err, data)))
  }
